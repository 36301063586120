import { Outlet } from "react-router-dom";
import React, { useState, useEffect, useMemo, useRef } from "react";

import { AgCharts } from "ag-charts-react";

import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid

export default function StatisticsPage() {
  const [stats, setStats] = useState([{}]);

  useEffect(() => {
    fetch("/api2/statistics/sent", { redirect: "manual" }).then((response) => {
      //const totalCount = response.headers.get("X-Total-Count");
      return response.json().then((data) => {
        //setStats(data);
        //return data;

        const groupedByMonth = data.reduce((acc, item) => {
          const month = item.date.substring(0, 7); // "2023-11"

          if (!acc[month]) {
            acc[month] = { month, totalSent: 0 };
          }

          acc[month].totalSent += item.sent;

          return acc;
        }, {});

        // Convert the grouped object into an array (if needed)
        const result = Object.values(groupedByMonth);
        console.log(result);
        setOptions((prevOptions) => ({
          ...prevOptions,
          data: result,
        }));
      });
    });
  }, []);

  const [options, setOptions] = useState({
    title: {
      text: "Contacted",
    },
    data: [],
    series: [
      {
        type: "bar",
        xKey: "month",
        yKey: "totalSent",
        yName: "Sent",
        interpolation: { type: "smooth" },
      },
    ],
  });

  return (
    <div
      style={{
        width: "400px",
        height: "300px",
      }}
    >
      <AgCharts options={options} />
    </div>
  );
}

function getData() {
  return [
    { date: "2023-11-01", sent: 1 },
    { date: "2023-11-02", sent: 0 },
    { date: "2023-11-03", sent: 1 },
    { date: "2023-11-04", sent: 0 },
    { date: "2023-11-05", sent: 0 },
    { date: "2023-11-06", sent: 0 },
    { date: "2023-11-07", sent: 0 },
    { date: "2023-11-08", sent: 0 },
    { date: "2023-11-09", sent: 0 },
    { date: "2023-11-10", sent: 0 },
    { date: "2023-11-11", sent: 0 },
    { date: "2023-11-12", sent: 0 },
    { date: "2023-11-13", sent: 0 },
    { date: "2023-11-14", sent: 0 },
    { date: "2023-11-15", sent: 0 },
    { date: "2023-11-16", sent: 0 },
    { date: "2023-11-17", sent: 0 },
    { date: "2023-11-18", sent: 0 },
    { date: "2023-11-19", sent: 0 },
    { date: "2023-11-20", sent: 0 },
    { date: "2023-11-21", sent: 0 },
    { date: "2023-11-22", sent: 0 },
    { date: "2023-11-23", sent: 6 },
    { date: "2023-11-24", sent: 7 },
    { date: "2023-11-25", sent: 0 },
    { date: "2023-11-26", sent: 0 },
    { date: "2023-11-27", sent: 5 },
    { date: "2023-11-28", sent: 3 },
    { date: "2023-11-29", sent: 10 },
    { date: "2023-11-30", sent: 6 },
    { date: "2023-12-01", sent: 0 },
    { date: "2023-12-02", sent: 0 },
    { date: "2023-12-03", sent: 0 },
    { date: "2023-12-04", sent: 3 },
    { date: "2023-12-05", sent: 2 },
    { date: "2023-12-06", sent: 6 },
    { date: "2023-12-07", sent: 0 },
    { date: "2023-12-08", sent: 1 },
    { date: "2023-12-09", sent: 0 },
    { date: "2023-12-10", sent: 0 },
    { date: "2023-12-11", sent: 11 },
    { date: "2023-12-12", sent: 4 },
    { date: "2023-12-13", sent: 0 },
    { date: "2023-12-14", sent: 0 },
    { date: "2023-12-15", sent: 0 },
    { date: "2023-12-16", sent: 0 },
    { date: "2023-12-17", sent: 0 },
    { date: "2023-12-18", sent: 0 },
    { date: "2023-12-19", sent: 0 },
    { date: "2023-12-20", sent: 0 },
    { date: "2023-12-21", sent: 0 },
    { date: "2023-12-22", sent: 0 },
    { date: "2023-12-23", sent: 0 },
    { date: "2023-12-24", sent: 0 },
    { date: "2023-12-25", sent: 0 },
    { date: "2023-12-26", sent: 0 },
    { date: "2023-12-27", sent: 0 },
    { date: "2023-12-28", sent: 3 },
    { date: "2023-12-29", sent: 0 },
    { date: "2023-12-30", sent: 0 },
    { date: "2023-12-31", sent: 0 },
    { date: "2024-01-01", sent: 0 },
    { date: "2024-01-02", sent: 3 },
    { date: "2024-01-03", sent: 0 },
    { date: "2024-01-04", sent: 9 },
    { date: "2024-01-05", sent: 5 },
    { date: "2024-01-06", sent: 0 },
    { date: "2024-01-07", sent: 0 },
    { date: "2024-01-08", sent: 7 },
    { date: "2024-01-09", sent: 4 },
    { date: "2024-01-10", sent: 17 },
    { date: "2024-01-11", sent: 9 },
    { date: "2024-01-12", sent: 7 },
    { date: "2024-01-13", sent: 0 },
    { date: "2024-01-14", sent: 5 },
    { date: "2024-01-15", sent: 7 },
    { date: "2024-01-16", sent: 1 },
    { date: "2024-01-17", sent: 1 },
    { date: "2024-01-18", sent: 3 },
    { date: "2024-01-19", sent: 4 },
    { date: "2024-01-20", sent: 0 },
    { date: "2024-01-21", sent: 0 },
    { date: "2024-01-22", sent: 3 },
    { date: "2024-01-23", sent: 9 },
    { date: "2024-01-24", sent: 6 },
    { date: "2024-01-25", sent: 16 },
    { date: "2024-01-26", sent: 0 },
    { date: "2024-01-27", sent: 0 },
    { date: "2024-01-28", sent: 0 },
    { date: "2024-01-29", sent: 14 },
    { date: "2024-01-30", sent: 7 },
    { date: "2024-01-31", sent: 4 },
    { date: "2024-02-01", sent: 12 },
    { date: "2024-02-02", sent: 1 },
    { date: "2024-02-03", sent: 0 },
    { date: "2024-02-04", sent: 0 },
    { date: "2024-02-05", sent: 11 },
    { date: "2024-02-06", sent: 3 },
    { date: "2024-02-07", sent: 6 },
    { date: "2024-02-08", sent: 6 },
    { date: "2024-02-09", sent: 0 },
    { date: "2024-02-10", sent: 0 },
    { date: "2024-02-11", sent: 0 },
    { date: "2024-02-12", sent: 3 },
    { date: "2024-02-13", sent: 3 },
    { date: "2024-02-14", sent: 1 },
    { date: "2024-02-15", sent: 0 },
    { date: "2024-02-16", sent: 0 },
    { date: "2024-02-17", sent: 0 },
    { date: "2024-02-18", sent: 0 },
    { date: "2024-02-19", sent: 21 },
    { date: "2024-02-20", sent: 1 },
    { date: "2024-02-21", sent: 2 },
    { date: "2024-02-22", sent: 1 },
    { date: "2024-02-23", sent: 2 },
    { date: "2024-02-24", sent: 0 },
    { date: "2024-02-25", sent: 0 },
    { date: "2024-02-26", sent: 5 },
    { date: "2024-02-27", sent: 7 },
    { date: "2024-02-28", sent: 0 },
    { date: "2024-02-29", sent: 7 },
    { date: "2024-03-01", sent: 0 },
    { date: "2024-03-02", sent: 0 },
    { date: "2024-03-03", sent: 0 },
    { date: "2024-03-04", sent: 2 },
    { date: "2024-03-05", sent: 7 },
    { date: "2024-03-06", sent: 8 },
    { date: "2024-03-07", sent: 0 },
    { date: "2024-03-08", sent: 0 },
    { date: "2024-03-09", sent: 0 },
    { date: "2024-03-10", sent: 0 },
    { date: "2024-03-11", sent: 5 },
    { date: "2024-03-12", sent: 7 },
    { date: "2024-03-13", sent: 0 },
    { date: "2024-03-14", sent: 2 },
    { date: "2024-03-15", sent: 1 },
    { date: "2024-03-16", sent: 0 },
    { date: "2024-03-17", sent: 0 },
    { date: "2024-03-18", sent: 7 },
    { date: "2024-03-19", sent: 2 },
    { date: "2024-03-20", sent: 0 },
    { date: "2024-03-21", sent: 1 },
    { date: "2024-03-22", sent: 0 },
    { date: "2024-03-23", sent: 0 },
    { date: "2024-03-24", sent: 0 },
    { date: "2024-03-25", sent: 0 },
    { date: "2024-03-26", sent: 0 },
    { date: "2024-03-27", sent: 0 },
    { date: "2024-03-28", sent: 0 },
    { date: "2024-03-29", sent: 0 },
    { date: "2024-03-30", sent: 0 },
    { date: "2024-03-31", sent: 0 },
    { date: "2024-04-01", sent: 0 },
    { date: "2024-04-02", sent: 0 },
    { date: "2024-04-03", sent: 0 },
    { date: "2024-04-04", sent: 0 },
    { date: "2024-04-05", sent: 0 },
    { date: "2024-04-06", sent: 0 },
    { date: "2024-04-07", sent: 0 },
    { date: "2024-04-08", sent: 54 },
    { date: "2024-04-09", sent: 4 },
    { date: "2024-04-10", sent: 1 },
    { date: "2024-04-11", sent: 6 },
    { date: "2024-04-12", sent: 1 },
    { date: "2024-04-13", sent: 0 },
    { date: "2024-04-14", sent: 0 },
    { date: "2024-04-15", sent: 2 },
    { date: "2024-04-16", sent: 6 },
    { date: "2024-04-17", sent: 0 },
    { date: "2024-04-18", sent: 5 },
    { date: "2024-04-19", sent: 2 },
    { date: "2024-04-20", sent: 0 },
    { date: "2024-04-21", sent: 0 },
    { date: "2024-04-22", sent: 5 },
    { date: "2024-04-23", sent: 5 },
    { date: "2024-04-24", sent: 6 },
    { date: "2024-04-25", sent: 2 },
    { date: "2024-04-26", sent: 1 },
    { date: "2024-04-27", sent: 0 },
    { date: "2024-04-28", sent: 1 },
    { date: "2024-04-29", sent: 6 },
    { date: "2024-04-30", sent: 4 },
    { date: "2024-05-01", sent: 0 },
    { date: "2024-05-02", sent: 10 },
    { date: "2024-05-03", sent: 1 },
    { date: "2024-05-04", sent: 0 },
    { date: "2024-05-05", sent: 0 },
    { date: "2024-05-06", sent: 2 },
    { date: "2024-05-07", sent: 0 },
    { date: "2024-05-08", sent: 3 },
    { date: "2024-05-09", sent: 0 },
    { date: "2024-05-10", sent: 2 },
    { date: "2024-05-11", sent: 0 },
    { date: "2024-05-12", sent: 0 },
    { date: "2024-05-13", sent: 7 },
    { date: "2024-05-14", sent: 0 },
    { date: "2024-05-15", sent: 3 },
    { date: "2024-05-16", sent: 2 },
    { date: "2024-05-17", sent: 0 },
    { date: "2024-05-18", sent: 0 },
    { date: "2024-05-19", sent: 0 },
    { date: "2024-05-20", sent: 2 },
    { date: "2024-05-21", sent: 3 },
    { date: "2024-05-22", sent: 0 },
    { date: "2024-05-23", sent: 2 },
    { date: "2024-05-24", sent: 0 },
    { date: "2024-05-25", sent: 0 },
    { date: "2024-05-26", sent: 0 },
    { date: "2024-05-27", sent: 1 },
    { date: "2024-05-28", sent: 0 },
    { date: "2024-05-29", sent: 0 },
    { date: "2024-05-30", sent: 9 },
    { date: "2024-05-31", sent: 0 },
    { date: "2024-06-01", sent: 0 },
    { date: "2024-06-02", sent: 0 },
    { date: "2024-06-03", sent: 2 },
    { date: "2024-06-04", sent: 2 },
    { date: "2024-06-05", sent: 0 },
    { date: "2024-06-06", sent: 0 },
    { date: "2024-06-07", sent: 0 },
    { date: "2024-06-08", sent: 0 },
    { date: "2024-06-09", sent: 0 },
    { date: "2024-06-10", sent: 6 },
    { date: "2024-06-11", sent: 0 },
    { date: "2024-06-12", sent: 4 },
    { date: "2024-06-13", sent: 1 },
    { date: "2024-06-14", sent: 0 },
    { date: "2024-06-15", sent: 0 },
    { date: "2024-06-16", sent: 0 },
    { date: "2024-06-17", sent: 0 },
    { date: "2024-06-18", sent: 4 },
    { date: "2024-06-19", sent: 0 },
    { date: "2024-06-20", sent: 5 },
    { date: "2024-06-21", sent: 0 },
    { date: "2024-06-22", sent: 0 },
    { date: "2024-06-23", sent: 0 },
    { date: "2024-06-24", sent: 2 },
    { date: "2024-06-25", sent: 0 },
    { date: "2024-06-26", sent: 2 },
    { date: "2024-06-27", sent: 1 },
    { date: "2024-06-28", sent: 0 },
    { date: "2024-06-29", sent: 0 },
    { date: "2024-06-30", sent: 0 },
    { date: "2024-07-01", sent: 0 },
    { date: "2024-07-02", sent: 5 },
    { date: "2024-07-03", sent: 2 },
    { date: "2024-07-04", sent: 0 },
    { date: "2024-07-05", sent: 0 },
    { date: "2024-07-06", sent: 0 },
    { date: "2024-07-07", sent: 0 },
    { date: "2024-07-08", sent: 4 },
    { date: "2024-07-09", sent: 1 },
    { date: "2024-07-10", sent: 3 },
    { date: "2024-07-11", sent: 3 },
    { date: "2024-07-12", sent: 0 },
    { date: "2024-07-13", sent: 0 },
    { date: "2024-07-14", sent: 0 },
    { date: "2024-07-15", sent: 0 },
    { date: "2024-07-16", sent: 0 },
    { date: "2024-07-17", sent: 0 },
    { date: "2024-07-18", sent: 0 },
    { date: "2024-07-19", sent: 0 },
    { date: "2024-07-20", sent: 0 },
    { date: "2024-07-21", sent: 0 },
    { date: "2024-07-22", sent: 0 },
    { date: "2024-07-23", sent: 0 },
    { date: "2024-07-24", sent: 0 },
    { date: "2024-07-25", sent: 0 },
    { date: "2024-07-26", sent: 0 },
    { date: "2024-07-27", sent: 0 },
    { date: "2024-07-28", sent: 0 },
    { date: "2024-07-29", sent: 0 },
    { date: "2024-07-30", sent: 0 },
    { date: "2024-07-31", sent: 0 },
    { date: "2024-08-01", sent: 0 },
    { date: "2024-08-02", sent: 0 },
    { date: "2024-08-03", sent: 0 },
    { date: "2024-08-04", sent: 0 },
    { date: "2024-08-05", sent: 0 },
    { date: "2024-08-06", sent: 0 },
    { date: "2024-08-07", sent: 0 },
    { date: "2024-08-08", sent: 0 },
    { date: "2024-08-09", sent: 0 },
    { date: "2024-08-10", sent: 0 },
    { date: "2024-08-11", sent: 0 },
    { date: "2024-08-12", sent: 9 },
    { date: "2024-08-13", sent: 7 },
    { date: "2024-08-14", sent: 6 },
    { date: "2024-08-15", sent: 0 },
    { date: "2024-08-16", sent: 1 },
    { date: "2024-08-17", sent: 0 },
    { date: "2024-08-18", sent: 0 },
    { date: "2024-08-19", sent: 14 },
    { date: "2024-08-20", sent: 11 },
    { date: "2024-08-21", sent: 0 },
    { date: "2024-08-22", sent: 15 },
    { date: "2024-08-23", sent: 0 },
    { date: "2024-08-24", sent: 0 },
    { date: "2024-08-25", sent: 0 },
    { date: "2024-08-26", sent: 13 },
    { date: "2024-08-27", sent: 2 },
    { date: "2024-08-28", sent: 1 },
    { date: "2024-08-29", sent: 5 },
    { date: "2024-08-30", sent: 0 },
    { date: "2024-08-31", sent: 0 },
    { date: "2024-09-01", sent: 0 },
    { date: "2024-09-02", sent: 3 },
    { date: "2024-09-03", sent: 1 },
    { date: "2024-09-04", sent: 3 },
    { date: "2024-09-05", sent: 1 },
    { date: "2024-09-06", sent: 3 },
    { date: "2024-09-07", sent: 0 },
    { date: "2024-09-08", sent: 0 },
    { date: "2024-09-09", sent: 2 },
    { date: "2024-09-10", sent: 9 },
    { date: "2024-09-11", sent: 7 },
    { date: "2024-09-12", sent: 1 },
    { date: "2024-09-13", sent: 0 },
    { date: "2024-09-14", sent: 0 },
    { date: "2024-09-15", sent: 0 },
    { date: "2024-09-16", sent: 5 },
    { date: "2024-09-17", sent: 7 },
    { date: "2024-09-18", sent: 3 },
    { date: "2024-09-19", sent: 1 },
    { date: "2024-09-20", sent: 1 },
    { date: "2024-09-21", sent: 0 },
    { date: "2024-09-22", sent: 0 },
    { date: "2024-09-23", sent: 6 },
    { date: "2024-09-24", sent: 3 },
    { date: "2024-09-25", sent: 1 },
    { date: "2024-09-26", sent: 0 },
    { date: "2024-09-27", sent: 0 },
    { date: "2024-09-28", sent: 0 },
    { date: "2024-09-29", sent: 0 },
    { date: "2024-09-30", sent: 2 },
    { date: "2024-10-01", sent: 0 },
    { date: "2024-10-02", sent: 0 },
    { date: "2024-10-03", sent: 0 },
    { date: "2024-10-04", sent: 2 },
    { date: "2024-10-05", sent: 0 },
    { date: "2024-10-06", sent: 0 },
    { date: "2024-10-07", sent: 5 },
    { date: "2024-10-08", sent: 3 },
    { date: "2024-10-09", sent: 1 },
    { date: "2024-10-10", sent: 2 },
    { date: "2024-10-11", sent: 2 },
    { date: "2024-10-12", sent: 0 },
    { date: "2024-10-13", sent: 0 },
    { date: "2024-10-14", sent: 5 },
    { date: "2024-10-15", sent: 6 },
    { date: "2024-10-16", sent: 2 },
    { date: "2024-10-17", sent: 5 },
    { date: "2024-10-18", sent: 1 },
    { date: "2024-10-19", sent: 0 },
    { date: "2024-10-20", sent: 0 },
    { date: "2024-10-21", sent: 8 },
    { date: "2024-10-22", sent: 2 },
    { date: "2024-10-23", sent: 2 },
    { date: "2024-10-24", sent: 1 },
    { date: "2024-10-25", sent: 1 },
    { date: "2024-10-26", sent: 0 },
    { date: "2024-10-27", sent: 0 },
    { date: "2024-10-28", sent: 0 },
    { date: "2024-10-29", sent: 0 },
    { date: "2024-10-30", sent: 1 },
    { date: "2024-10-31", sent: 2 },
    { date: "2024-11-01", sent: 0 },
    { date: "2024-11-02", sent: 0 },
    { date: "2024-11-03", sent: 0 },
    { date: "2024-11-04", sent: 13 },
    { date: "2024-11-05", sent: 4 },
    { date: "2024-11-06", sent: 5 },
    { date: "2024-11-07", sent: 1 },
    { date: "2024-11-08", sent: 0 },
    { date: "2024-11-09", sent: 0 },
    { date: "2024-11-10", sent: 0 },
    { date: "2024-11-11", sent: 5 },
    { date: "2024-11-12", sent: 5 },
    { date: "2024-11-13", sent: 5 },
    { date: "2024-11-14", sent: 11 },
    { date: "2024-11-15", sent: 6 },
    { date: "2024-11-16", sent: 0 },
    { date: "2024-11-17", sent: 0 },
    { date: "2024-11-18", sent: 12 },
    { date: "2024-11-19", sent: 8 },
    { date: "2024-11-20", sent: 7 },
    { date: "2024-11-21", sent: 10 },
    { date: "2024-11-22", sent: 1 },
    { date: "2024-11-23", sent: 0 },
    { date: "2024-11-24", sent: 0 },
    { date: "2024-11-25", sent: 7 },
    { date: "2024-11-26", sent: 4 },
    { date: "2024-11-27", sent: 10 },
    { date: "2024-11-28", sent: 7 },
    { date: "2024-11-29", sent: 0 },
    { date: "2024-11-30", sent: 0 },
    { date: "2024-12-01", sent: 0 },
    { date: "2024-12-02", sent: 6 },
    { date: "2024-12-03", sent: 5 },
    { date: "2024-12-04", sent: 0 },
    { date: "2024-12-05", sent: 6 },
    { date: "2024-12-06", sent: 0 },
    { date: "2024-12-07", sent: 0 },
    { date: "2024-12-08", sent: 0 },
    { date: "2024-12-09", sent: 4 },
  ];
}
