import React from "react";
import ReactDOM from "react-dom/client";
import ProspectPage from "./ProspectPage";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import BasePage from "./BasePage";
import CompanyPage from "./CompanyPage";
import StatisticsPage from "./StatisticsPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <BasePage />,
    children: [
      {
        path: "/",
        element: <ProspectPage />,
      },
      {
        path: "/companies",
        element: <CompanyPage />,
      },
      {
        path: "/statistics",
        element: <StatisticsPage />,
      },
    ],
  },
]);
ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
