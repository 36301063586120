import React, { useState, useEffect, useMemo, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid

import "./index.css";

import Navbar from "./Navbar";

function renderState() {
  return "Uncontacted";
}

const url = "/api2/prospects/";

function ProspectPage() {
  useEffect(() => {
    fetch(url, { redirect: "manual" }).then((response) => {
      //const totalCount = response.headers.get("X-Total-Count");
      return response.json().then((data) => {
        setRowData(data);
        return data;
      });
    });
  }, []);

  const [rowData, setRowData] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const gridApiRef = useRef(null);

  //https://docs.google.com/spreadsheets/d/1DG2oGjVKE5Vn_eD93oBKvBCKWcdbP0_h3zBS7rX1up8/edit?gid=723516482#gid=723516482&range=A200

  const CustomLinkedinCell = (props) => {
    var linkedId = props.getValue();
    var linkedUrl = "https://linkedin.com/in/" + linkedId + "/";
    return (
      <a href={linkedUrl} target="_new">
        {linkedId}
      </a>
    );
  };

  const CustomRowCell = (props) => {
    var row = props.getValue();
    var rowUrl =
      "https://docs.google.com/spreadsheets/d/1DG2oGjVKE5Vn_eD93oBKvBCKWcdbP0_h3zBS7rX1up8/edit?gid=723516482#gid=723516482&range=A" +
      row +
      "/";
    return (
      <a href={rowUrl} target="_new">
        {row}
      </a>
    );
  };

  const CustomButtonComponent = (props) => {
    var notes = props.getValue();

    if (typeof notes !== "undefined") {
      if (notes.length > 0) {
        return <span>Notes</span>;
      } else {
        return <></>;
      }
    }
  };

  function addContactDate(linkedinId) {
    console.log("add date to " + linkedinId);
    var url = "/api2/prospects/addContactDate/" + linkedinId;

    fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        console.log("Contaced dates updated:");
        console.log(response);
        return response.json();
      })
      .then((result) => {
        console.log(result);
        console.log(gridApi);
        gridApiRef.current.applyTransaction({ update: [result] });
      });
  }
  const ContactDatesRenderer = (props) => {
    var contactDates = props.getValue();

    return (
      <span>
        <button onClick={() => addContactDate(props.data.linkedinId)}>+</button>{" "}
        {contactDates.join(", ")}
      </span>
    );
  };

  const RecruiterRenderer = (props) => {
    var value = props.getValue();
    if (value === "hannes.bjurek") {
      return "Hannes";
    } else if (value === "mikael.backstrom") {
      return "Mikael";
    }
  };
  // Column Definitions: Defines the columns to be displayed.
  const [colDefs, setColDefs] = useState([
    { field: "row", sort: "desc", width: 70, cellRenderer: CustomRowCell },
    {
      field: "firstName",
      width: 130,
      filter: true,
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
    },
    {
      field: "lastName",
      width: 130,
      filter: true,
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
    },
    { field: "linkedinId", cellRenderer: CustomLinkedinCell, width: 250 },
    {
      field: "assignedRecruiter",
      headerName: "Recruiter",
      filter: true,
      cellRenderer: RecruiterRenderer,
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: ["hannes.bjurek", "mikael.backstrom"],
      },
      width: 130,
      editable: true,
    },

    {
      field: "contactDates",

      cellRenderer: ContactDatesRenderer,
    },

    { field: "currentEmployer" },
    {
      field: "notes",
      editable: true,
      // cellRenderer: CustomButtonComponent,
      // width: 70,
      cellEditor: "agLargeTextCellEditor",
      cellEditorPopup: true,
      flex: 2,
    },
  ]);

  function notesRender(value) {
    if (value.length > 2) {
      return "N";
    } else {
      return "";
    }
  }

  function recruiterRender(value) {}

  function datesRender(value) {
    if (value.length === 0) {
      return "";
    } else if (value.length === 1) {
      return value;
    } else if (value.length === 2) {
      return value[0] + ", " + value[1];
    }
  }

  const rowSelection = useMemo(() => {
    return {
      mode: "singleRow",
      checkboxes: false,
      enableClickSelection: true,
    };
  }, []);

  function onCellValueChanged(event) {
    var url = "/api2/prospects/";

    url = url + event.data.linkedinId;
    console.log("contactDates:" + event.data.contactDates);
    fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(event.data),
    })
      .then((response) => {
        console.log("User updated:");
        console.log(response);
      })
      .then((result) => {
        console.log("Success:", result);
      });
    console.log("cell edit:" + event);
  }

  const getRowId = (params) => params.data.linkedinId;

  const onGridReady = (params) => {
    setGridApi(params.api);
    gridApiRef.current = params.api;
  };

  return (
    <>
      <div
        className="ag-theme-quartz" // applying the Data Grid theme
        style={{}} // the Data Grid will fill the size of the parent container
      >
        <AgGridReact
          rowData={rowData}
          columnDefs={colDefs}
          rowSelection={rowSelection}
          onCellValueChanged={onCellValueChanged}
          getRowId={getRowId}
          onGridReady={onGridReady}
        />
      </div>
    </>
  );
}

export default ProspectPage;
