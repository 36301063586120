import { Outlet } from "react-router-dom";
import React, { useState, useEffect, useMemo, useRef } from "react";

import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid

export default function CompanyPage() {
  const [rowData, setRowData] = useState([]);

  const CustomLinkedinCell = (props) => {
    var linkedId = props.getValue();
    var linkedUrl = "https://linkedin.com/company/" + linkedId + "/";
    return (
      <a href={linkedUrl} target="_new">
        {linkedId}
      </a>
    );
  };

  const OrgNumberCell = (props) => {
    var orgNumner = props.getValue();
    orgNumner = orgNumner.replace(/-/g, "");
    var allabolagUrl = "https://www.allabolag.se/" + orgNumner + "/";
    return (
      <a href={allabolagUrl} target="_new">
        {orgNumner}
      </a>
    );
  };

  const [colDefs, setColDefs] = useState([
    {
      field: "orgNumber",
      sort: "desc",
      width: 160,
      cellRenderer: OrgNumberCell,
    },
    { field: "name", width: 150 },
    { field: "linkedinId", cellRenderer: CustomLinkedinCell },
  ]);

  useEffect(() => {
    fetch("/api2/companies/", { redirect: "manual" }).then((response) => {
      //const totalCount = response.headers.get("X-Total-Count");
      return response.json().then((data) => {
        setRowData(data);
        return data;
      });
    });
  }, []);
  return (
    <>
      <div
        className="ag-theme-quartz-dark" // applying the Data Grid theme
        style={{}} // the Data Grid will fill the size of the parent container
      >
        <AgGridReact
          rowData={rowData}
          columnDefs={colDefs}
          //rowSelection={rowSelection}
          //onCellValueChanged={onCellValueChanged}
          //getRowId={getRowId}
          //onGridReady={onGridReady}
        />
      </div>
    </>
  );
}
